<template>
  <div>
    <h2 class="headline font-weight-bold mb-4 d-flex align-center">
      <img
        height="36"
        width="36"
        src="@/assets/svg/icon_teachers.svg"
        class="mr-2"
      />
      {{ isTeacher ? $t("app.teacher") : "Staff" }}
    </h2>

    <v-row>
      <!-- Start Absent -->
      <v-col cols="12" sm="6" lg="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          class="d-flex pa-5 mb-4 rounded-lg flex-column"
          flat
        >
          <div class="title font-weight-bold">
            {{ $t("dashboard.absent") }}
          </div>
          <div class="d-flex align-center">
            <div>
              <div
                :class="colorIcon"
                class="my-2 rounded-circle d-flex justify-center align-center"
                style="width: 70px; height: 70px"
              >
                <v-icon color="white" large>
                  mdi-file-document-box
                </v-icon>
              </div>
            </div>
            <v-spacer />
            <div class="text-center">
              <div class="display-1 font-weight-bold">
                {{ dataEmployeeAbsent.count }}
              </div>
              <div class="title font-weight-bold">
                {{ isTeacher ? $t("app.teacher") : "Staff" }}
              </div>
            </div>
          </div>
          <div class="caption d-flex align-center">
            <v-icon color="white" small class="grey rounded-xl mr-2">
              mdi-exclamation
            </v-icon>
            {{ dataEmployeeAbsent.presentage }}%
            {{
              $t(`dashboard.${isTeacher ? "absent_teacher" : "absent_staff"}`)
            }}
          </div>
        </v-card>
      </v-col>
      <!-- End Absent -->

      <!-- Start Prospective Employee -->
      <v-col cols="12" sm="6" lg="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          class="d-flex pa-5 mb-4 rounded-lg flex-column"
          flat
        >
          <div class="title font-weight-bold">
            {{
              $t(
                `dashboard.${
                  isTeacher ? "prospective_teachers" : "prospective_staff"
                }`
              )
            }}
          </div>
          <div class="d-flex align-center mb-5">
            <div>
              <div
                :class="colorIcon"
                class="my-2 rounded-circle d-flex justify-center align-center"
                style="width: 70px; height: 70px"
              >
                <v-icon color="white" large>
                  mdi-account-multiple
                </v-icon>
              </div>
            </div>
            <v-spacer />
            <div class="text-center">
              <div class="display-1 font-weight-bold">
                {{ dataEmployeeApplicant.count }}
              </div>
              <div class="title font-weight-bold">
                {{ isTeacher ? $t("app.teacher") : "Staff" }}
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
      <!-- End Prospective Employee -->

      <!-- Start Active Employee -->
      <v-col cols="12" sm="6" lg="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          class="d-flex pa-5 mb-4 rounded-lg flex-column"
          flat
        >
          <div class="title font-weight-bold">
            {{
              $t(`dashboard.${isTeacher ? "active_teacher" : "active_staff"}`)
            }}
          </div>
          <div class="d-flex align-center">
            <div>
              <div
                :class="colorIcon"
                class="my-2 rounded-circle d-flex justify-center align-center"
                style="width: 70px; height: 70px"
              >
                <v-icon color="white" large>
                  mdi-account-check
                </v-icon>
              </div>
            </div>
            <v-spacer />
            <div class="text-center">
              <div class="display-1 font-weight-bold">
                {{ dataEmployeeActive.count }}
              </div>
              <div class="title font-weight-bold">
                {{ isTeacher ? $t("app.teacher") : "Staff" }}
              </div>
            </div>
          </div>
          <div class="caption d-flex align-center">
            <v-icon color="white" small class="grey rounded-xl mr-2">
              mdi-exclamation
            </v-icon>
            {{ $t("app.school_year") }}
            {{ dataEmployeeActive.school_year_master.start_year }} /
            {{ dataEmployeeActive.school_year_master.end_year }}
          </div>
        </v-card>
      </v-col>
      <!-- End Active Employee -->

      <!-- Start Teacher Mutation -->
      <v-col cols="12" sm="6" lg="3">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          class="d-flex pa-5 mb-4 rounded-lg flex-column"
          flat
        >
          <div class="title font-weight-bold">
            {{
              $t(
                `dashboard.${isTeacher ? "teacher_mutation" : "staff_mutation"}`
              )
            }}
          </div>
          <div class="d-flex align-center">
            <div>
              <div
                :class="colorIcon"
                class="my-2 rounded-circle d-flex justify-center align-center"
                style="width: 70px; height: 70px"
              >
                <v-icon color="white" large>
                  mdi-account-remove
                </v-icon>
              </div>
            </div>
            <v-spacer />
            <div class="text-center">
              <div class="display-1 font-weight-bold">
                {{ dataEmployeeMutation.count }}
              </div>
              <div class="title font-weight-bold">
                {{ isTeacher ? $t("app.teacher") : "Staff" }}
              </div>
            </div>
          </div>
          <div class="caption d-flex align-center">
            <v-icon color="white" small class="grey rounded-xl mr-2">
              mdi-exclamation
            </v-icon>
            {{ $t("app.school_year") }}
            {{ dataEmployeeMutation.school_year_master.start_year }} /
            {{ dataEmployeeMutation.school_year_master.end_year }}
          </div>
        </v-card>
        <!-- End Teacher Mutation -->
      </v-col>
      <!-- <v-col cols="12" md="6">
        <v-card
          :class="darkmode ? 'black' : 'white'"
          flat
          class="pa-4 rounded-lg"
          min-height="200"
        >
          <div class="title font-weight-bold mb-4 d-flex flex-row align-center">
            {{ $t("dashboard.violation") }}
            <v-spacer />
            <v-select
              v-model="modelFilter"
              :items="filters"
              item-text="name"
              item-value="id"
              hide-details
              outlined
              dense
              class="select-150"
            />
          </div>
          <LineChart
            v-if="!loadingLineChart"
            :chart-data="lineChart"
            legend
            tooltipsDisplayColor
          />
        </v-card>
      </v-col> -->
    </v-row>
  </div>
</template>

<script>
import {
  employeeAbsent,
  employeeApplicant,
  employeeActive,
  employeeMutation
  // employeeViolation
} from "@/api/admin/dashboard";

export default {
  props: {
    isTeacher: Boolean
  },
  created() {
    this.getEmployeeAbsent();
    this.getEmployeeApplicant();
    this.getEmployeeActive();
    this.getEmployeeMutation();
    // this.getEmployeeViolation();
  },
  computed: {
    darkmode() {
      return this.$store.getters.g_darkmode;
    }
  },
  // components: { LineChart: () => import("@/components/Chart/LineChart") },
  data() {
    return {
      colorIcon: this.isTeacher ? "gradient-primary-dark" : "gradient-error",
      employeeType: this.isTeacher ? "teacher" : "staff",
      loadingLineChart: false,
      lineChart: {
        labels: [],
        datasets: []
      },
      dataEmployeeAbsent: {
        count: 0,
        presentage: 0
      },
      dataEmployeeApplicant: {
        count: 0
      },
      dataEmployeeActive: {
        count: 0,
        school_year_master: {
          id: 0,
          institution: "",
          start_year: 0,
          end_year: 0
        }
      },
      dataEmployeeMutation: {
        count: 0,
        school_year_master: {
          id: 0,
          institution: "",
          start_year: 0,
          end_year: 0
        }
      },
      modelFilter: "weekly",
      filters: [
        { id: "weekly", name: this.$i18n.t("dashboard.weekly") },
        { id: "monthly", name: this.$i18n.t("dashboard.monthly") },
        { id: "annual", name: this.$i18n.t("dashboard.annual") }
      ]
    };
  },
  methods: {
    snackBar(isError, msg) {
      this.$store.commit("CALL_SNACKBAR", {
        msg: msg,
        color: isError ? "error" : "success"
      });
    },
    getEmployeeAbsent() {
      employeeAbsent({ type: this.employeeType })
        .then(res => {
          if (res.data.code) this.dataEmployeeAbsent = res.data.data;
          else this.snackBar(true, res.data.message);
        })
        .catch(e => console.error("getEmployeeAbsent()\n", e));
    },
    // async getEmployeeViolation() {
    //   this.loadingLineChart = true;
    //   try {
    //     const response = await employeeViolation();
    //     if (response.data.code) {
    //       const result = response.data.data;
    //       this.lineChart.labels = result.diagram.map(
    //         (item, index) => this.$i18n.t("app.days")[index]
    //       );
    //       this.lineChart.datasets = [
    //         {
    //           label: this.$i18n.t("app.teacher"),
    //           backgroundColor: "rgba(255,0,0,0.3)",
    //           pointBackgroundColor: "#FF6396",
    //           pointBorderColor: "#FF6396",
    //           borderColor: "#FF6396",
    //           data: result.diagram.map(item => item.teacher.count)
    //         },
    //         {
    //           label: "Staff",
    //           backgroundColor: "rgba(0,0,255,0.3)",
    //           pointBackgroundColor: "#3E4295",
    //           pointBorderColor: "#3E4295",
    //           borderColor: "#3E4295",
    //           data: result.diagram.map(item => item.staff.count)
    //         }
    //       ];
    //     } else {
    //       this.snackBar(true, response.data.message);
    //     }
    //   } catch (error) {
    //     this.snackBar(true, error);
    //   }
    //   this.loadingLineChart = false;
    // },
    getEmployeeMutation() {
      employeeMutation({ type: this.employeeType })
        .then(res => {
          const data = res.data;
          if (data.code) {
            if (data.data.school_year_master) {
              this.dataEmployeeMutation = res.data.data;
            }
          } else this.snackBar(true, res.data.message);
        })
        .catch(e => console.error("getEmployeeMutation()\n", e));
    },
    getEmployeeActive() {
      employeeActive({ type: this.employeeType })
        .then(res => {
          const data = res.data;
          if (data.code) {
            if (data.data.school_year_master) {
              this.dataEmployeeActive = res.data.data;
            }
          } else this.snackBar(true, res.data.message);
        })
        .catch(e => console.error("getEmployeeActive()\n", e));
    },
    getEmployeeApplicant() {
      employeeApplicant({ type: this.employeeType })
        .then(res => {
          if (res.data.code) this.dataEmployeeApplicant = res.data.data;
          else this.snackBar(true, res.data.message);
        })
        .catch(e => console.error("getEmployeeApplicant()\n", e));
    }
  }
};
</script>
